<template>
  <!--begin::row-->
  <b-row no-gutters class="font-size-h6 mb-4">
    <!--begin::Event=-->
    <b-col cols="12" md="8" class="text-gray-600 font-weight-bold">
      {{ $t('label.admin') }}
      <router-link
        :to="{
          name: routeName.ACCOUNT_MANAGEMENT_DETAILS,
          params: { id: log.adminUserId }
        }"
        class="font-weight-bolder text-hover-primary"
        >{{ log.adminUserName }}
      </router-link>
      <span v-html="getActionMessage(log)"></span>
    </b-col>
    <!--end::Event=-->

    <!--begin::Timestamp=-->
    <b-col cols="12" md="4" class="text-gray-600 text-end">
      {{ log.createdAt }}
    </b-col>
    <!--end::Timestamp=-->
  </b-row>
  <!--end::row-->
</template>

<script>
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'AuditLogActionRowIsAccountDeactivated',
    props: {
      log: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      routeName: ROUTE_NAME
    }),
    methods: {
      getActionMessage(log) {
        var message = i18nHelper.getMessage(
          'message.hasDoneAnInvalidOperation'
        );

        if (log.fromValue == '0' && log.toValue == '1') {
          message = i18nHelper.getMessage(
            'message.hasBlockedThisUserFromLoggingIntoTheApplication'
          );
        } else if (log.fromValue == '1' && log.toValue == '0') {
          message = i18nHelper.getMessage(
            'message.hasAllowedThisUserToLogInToTheApplication'
          );
        }

        return message;
      }
    }
  };
</script>

<style></style>
