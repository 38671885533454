<template>
  <b-card>
    <template v-if="showAuditLogsCardHeader" #header>
      <h3 class="text-capitalize">{{ $t('label.logs') }}</h3>
    </template>

    <!-- begin:: Card Body -->
    <!-- begin:: Logs is readable -->
    <div v-if="isAuditLogsReadable">
      <!-- begin:: Logs not empty -->
      <div v-if="auditLogs.length > 0">
        <!-- begin:: Logs -->
        <template v-for="(log, index) in auditLogs">
          <div :key="index" class="py-4">
            <!-- begin:: Component -->
            <component :is="getComponent(log)" :log="log"></component>
            <!-- end:: Component -->
            <div class="separator separator-dashed"></div>
          </div>
        </template>
        <!-- end:: Logs -->

        <AppTablePagination
          :pagination="pagination"
          @change="onChangePagination"
        ></AppTablePagination>
      </div>
      <!-- end:: Logs not empty -->

      <!-- begin:: Logs is empty -->
      <span v-else>{{ $t('message.thereAreNoRecordsToShow') }}</span>
      <!-- end:: Logs is empty -->
    </div>
    <!-- end:: Logs is readable -->

    <!-- begin:: Logs is not readable -->
    <div v-else>
      <span>{{ $t('message.noPermissionToReadAuditLog') }}</span>
    </div>
    <!-- end:: Logs is not readable -->
    <!-- end:: Card Body -->
  </b-card>
</template>

<script>
  import AuditLogActionRowIsActive from '@/views/components/audit-logs/actions/AuditLogActionRowIsActive';
  import AuditLogActionRowIsDeleted from '@/views/components/audit-logs/actions/AuditLogActionRowIsDeleted';
  import AuditLogActionRowIsAccountDeactivated from '@/views/components/audit-logs/actions/AuditLogActionRowIsAccountDeactivated';
  import AuditLogActionRowIsLoginBlocked from '@/views/components/audit-logs/actions/AuditLogActionRowIsLoginBlocked';
  import AuditLogActionRowVerificationStatus from '@/views/components/audit-logs/actions/AuditLogActionRowVerificationStatus';
  import AppTablePagination from '@/views/components/bases/pagination/AppTablePagination';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME, GLOBAL, MODULE, ACTION } from '@/core/constants';
  import { AUDIT_LOG_COLUMN_NAME } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    AUDIT_LOGS_GET_AUDIT_LOGS,
    AUDIT_LOGS_INITIAL_GET_AUDIT_LOGS_STATE
  } from '@/core/store/audit-logs.module';

  export default {
    name: 'AuditLogsCard',
    components: {
      AuditLogActionRowIsActive,
      AppTablePagination,
      AuditLogActionRowIsDeleted,
      AuditLogActionRowIsAccountDeactivated,
      AuditLogActionRowIsLoginBlocked,
      AuditLogActionRowVerificationStatus
    },
    mixins: [commonMixin],
    props: {
      tableId: {
        type: Number,
        default: null
      },
      logType: {
        type: String,
        default: ''
      },
      showAuditLogsCardHeader: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      auditLogs: [],
      searchCriteria: GLOBAL.DEFAULT_SEARCH_CRITERIA,
      pagination: {
        currentPage: 1,
        total: 1,
        perPage: 10,
        lastPage: 1
      }
    }),
    computed: {
      auditLogsComplete() {
        return this.$store.state.auditLogs.auditLogs.complete;
      },
      isAuditLogsReadable() {
        return this.checkAccessRight(MODULE.AUDIT_LOG, [ACTION.AUDIT_LOG_READ]);
      }
    },
    watch: {
      auditLogsComplete() {
        let response = this.$store.state.auditLogs.auditLogs;
        let title = i18nHelper.getMessage('label.getAuditLogs');
        let initialStateAction = AUDIT_LOGS_INITIAL_GET_AUDIT_LOGS_STATE;
        let successAction = (response) => {
          this.getAuditLogsSucceedAction(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initAuditLogsCard();
    },
    methods: {
      updateSearchCriteria(page, perPage) {
        this.searchCriteria = {
          ...this.searchCriteria,
          page: page,
          perPage: perPage
        };
        this.pagination = {
          ...this.pagination,
          currentPage: page,
          perPage: perPage
        };

        this.getAuditLogs(this.searchCriteria);
      },
      onChangePagination(value) {
        this.updateSearchCriteria(value.page, value.size);
      },
      getComponent(log) {
        const components = {
          [AUDIT_LOG_COLUMN_NAME.IS_ACTIVE]: 'AuditLogActionRowIsActive',
          [AUDIT_LOG_COLUMN_NAME.IS_DELETED]: 'AuditLogActionRowIsDeleted',
          [AUDIT_LOG_COLUMN_NAME.IS_ACCOUNT_DEACTIVATED]:
            'AuditLogActionRowIsAccountDeactivated',
          [AUDIT_LOG_COLUMN_NAME.IS_LOGIN_BLOCKED]:
            'AuditLogActionRowIsLoginBlocked',
          [AUDIT_LOG_COLUMN_NAME.VERIFICATION_STATUS]:
            'AuditLogActionRowVerificationStatus'
        };

        return components[log.columnName];
      },
      getAuditLogsSucceedAction(response) {
        this.auditLogs = response.data;
        this.pagination = response.pagination;
      },
      getAuditLogs(data) {
        this.$store.dispatch(AUDIT_LOGS_GET_AUDIT_LOGS, { data });
      },
      initAuditLogsCard() {
        this.searchCriteria = {
          ...GLOBAL.DEFAULT_SEARCH_CRITERIA,
          tableId: this.tableId,
          tableName: this.logType
        };

        if (this.isAuditLogsReadable) {
          this.getAuditLogs(this.searchCriteria);
        }
      }
    }
  };
</script>

<style></style>
