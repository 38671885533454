<template>
  <div class="full-wh" @click="$emit('onclose')">
    <div class="img_cont">
      <b-img v-if="value" :src="value" class="image_View" />
      <b-avatar v-else square size="400px" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ImageViewer',
    props: ['value']
  };
</script>

<style>
  .full-wh {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
  }
  .img_cont {
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .full-wh img {
    width: auto;
    height: 100%;
  }
</style>
