<template>
  <!--begin::row-->
  <b-row no-gutters class="font-size-h6 mb-4">
    <!--begin::Event=-->
    <b-col cols="12" md="8" class="text-gray-600 font-weight-bold">
      {{ $t('label.admin') }}
      <router-link
        :to="{
          name: routeName.ACCOUNT_MANAGEMENT_DETAILS,
          params: { id: log.adminUserId }
        }"
        class="font-weight-bolder text-hover-primary"
        >{{ log.adminUserName }}</router-link
      >
      {{ $t('message.hasChangedTheStatusFrom') }}
      <AppActivationStatusLabel
        class="d-inline"
        :value="transformValueToBoolean(log.fromValue)"
      ></AppActivationStatusLabel>
      <span class="text-lowercase"> {{ $t('label.to') }} </span>
      <AppActivationStatusLabel
        class="d-inline"
        :value="transformValueToBoolean(log.toValue)"
      ></AppActivationStatusLabel>
    </b-col>
    <!--end::Event=-->

    <!--begin::Timestamp=-->
    <b-col cols="12" md="4" class="text-gray-600 text-end min-w-200px">
      {{ log.createdAt }}
    </b-col>
    <!--end::Timestamp=-->
  </b-row>
  <!--end::row-->
</template>

<script>
  import AppActivationStatusLabel from '@/views/components/bases/AppActivationStatusLabel';
  import { ROUTE_NAME } from '@/core/constants';

  export default {
    name: 'AuditLogActionRowIsActive',
    components: {
      AppActivationStatusLabel
    },
    props: {
      log: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      routeName: ROUTE_NAME
    }),
    methods: {
      transformValueToBoolean(value) {
        return Boolean(Number(value));
      }
    }
  };
</script>

<style></style>
