<template>
  <div>
    <!-- begin: Button Type 1 -->
    <label
      v-if="buttonType == 1"
      class="text-primary cursor-pointer mb-0 font-size-sm"
      @click="viewDocument"
    >
      {{ $t('label.view') }}
    </label>
    <!-- end: Button Type 1 -->

    <!-- begin: Button Type 2 -->
    <b-button
      v-else-if="buttonType == 2"
      variant="primary shadow-sm font-size-sm"
      @click="viewDocument"
    >
      {{ $t('label.preview') }}
    </b-button>
    <!-- end: Button Type 2 -->
  </div>
</template>

<script>
  import { uiHelper } from '@/core/utils';

  export default {
    name: 'AppButtonDocumentView',
    props: {
      title: {
        type: String,
        default: ''
      },
      attachmentUrl: {
        type: String,
        default: ''
      },
      fileType: {
        type: String,
        default: ''
      },
      buttonType: {
        type: Number,
        default: 1
      }
    },
    data: () => ({
      modal: false
    }),
    methods: {
      viewDocument() {
        let imagesURL = [this.attachmentUrl];
        let filetype = uiHelper.getFileExtension(this.attachmentUrl);

        if (filetype == 'image') {
          /* Toolbar options refer to 
          https://github.com/fengyuanchen/viewerjs */

          this.$viewerApi({
            options: {
              toolbar: {
                zoomIn: true,
                zoomOut: true,
                oneToOne: true,
                reset: true,
                prev: false,
                play: {
                  show: true,
                  size: 'large'
                },
                next: false,
                rotateLeft: true,
                rotateRight: true,
                flipHorizontal: true,
                flipVertical: true
              },
              navbar: false
            },
            images: imagesURL
          });
        } else if (filetype == 'document') {
          uiHelper.openPage(this.attachmentUrl, '_blank', 'fullscreen=1');
        } else {
          uiHelper.openPage(this.attachmentUrl, '_parent');
        }
      }
    }
  };
</script>

<style lang="scss"></style>
