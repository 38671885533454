<template>
  <!--begin::row-->
  <b-row no-gutters class="font-size-h6 mb-4">
    <!--begin::Event=-->
    <b-col cols="12" md="8" class="text-gray-600 font-weight-bold">
      {{ $t('label.admin') }}
      <router-link
        :to="{
          name: routeName.ACCOUNT_MANAGEMENT_DETAILS,
          params: { id: log.adminUserId }
        }"
        class="font-weight-bolder text-hover-primary"
        >{{ log.adminUserName }}</router-link
      >
      {{ $t('message.hasChangedTheStatusFrom') }}
      <AppEnumLabel
        class="d-inline"
        :value="log.fromValue"
        :config="config"
      ></AppEnumLabel>
      <span class="text-lowercase"> {{ $t('label.to') }} </span>
      <AppEnumLabel
        class="d-inline"
        :value="log.toValue"
        :config="config"
      ></AppEnumLabel>
    </b-col>
    <!--end::Event=-->

    <!--begin::Timestamp=-->
    <b-col cols="12" md="4" class="text-gray-600 text-right min-w-200px">
      {{ log.createdAt }}
    </b-col>
    <!--end::Timestamp=-->
  </b-row>
  <!--end::row-->
</template>

<script>
  import AppEnumLabel from '@/views/components/bases/AppEnumLabel';
  import { ProfileVerificationStatus } from '@/core/constants/enums';
  import { ROUTE_NAME } from '@/core/constants';

  export default {
    name: 'AuditLogActionRowVerificationStatus',
    components: {
      AppEnumLabel
    },
    props: {
      log: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      config: {
        size: 'lg',
        enumKey: 'enumProfileVerificationStatus',
        statusColor: (value) => {
          let color = {
            [ProfileVerificationStatus.PENDING]: 'warning',
            [ProfileVerificationStatus.SUBMITTED]: 'primary',
            [ProfileVerificationStatus.REJECTED]: 'danger',
            [ProfileVerificationStatus.APPROVED]: 'success'
          };

          return color[value];
        }
      }
    }),
    methods: {
      transformValueToBoolean(value) {
        return Boolean(Number(value));
      }
    }
  };
</script>

<style></style>
